import nookies, { setCookie as setNookie, parseCookies } from 'nookies';
import isUndefined from 'lodash/isUndefined';
import env from './env';
import {
  GDPR_STATISTICS_ENABLED,
  GDPR_FUNCTIONAL_ENABLED,
  GDPR_CONSENT_GIVEN,
  PREFERRED_LOGIN_VARIANT,
} from './constants';

const { GDPR_VERSION } = env;

const getCookieDomain = (ctx?: any): string => {
  let host: string;

  if (typeof window !== 'undefined') {
    console.log('BROWSER');
    host = window.location.host;
  } else if (ctx && ctx.req) {
    console.log('SERVERSIDE');
    host = ctx.req.headers.host || '';
  } else {
    return '';
  }
  console.log('🚀 ~ getCookieDomain ~ host:', host);

  const [, ...restOfHost] = host.split('.');
  return restOfHost.join('.');
};

const MAX_32_BIT_DATE = 2147483647;

const getExpireTime = (days?) => {
  const date = new Date();
  if (isUndefined(days)) {
    date.setTime(MAX_32_BIT_DATE * 1000);
  } else {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  }
  return date;
};

const setCookie = async (name, value, expireTimeInDays?): Promise<object> =>
  await setNookie(null, name, value, {
    expires: getExpireTime(expireTimeInDays),
    domain: getCookieDomain(),
    path: '/',
  });

export const setServerSideCookie = async (
  ctx,
  name,
  value,
  expireTimeInDays?,
): Promise<object> =>
  await nookies.set(ctx, name, value, {
    expires: getExpireTime(expireTimeInDays),
    domain: getCookieDomain(ctx),
    path: '/',
  });

export const setToken = async (
  value: string,
  expireTimeInDays = 1,
): Promise<void> => {
  await setCookie('token', value, expireTimeInDays);
};

export const setUser = (value, expireTimeInDays = 14) => {
  setCookie('user', JSON.stringify(value), expireTimeInDays);
};

export const setGdpr = () => {
  setCookie('gdprVersion', GDPR_VERSION);
};

export const setCookiesConsentGiven = (): void => {
  setCookie(GDPR_CONSENT_GIVEN, GDPR_VERSION, 365);
};

export const resetCookiesConsent = (): void => {
  setCookie(GDPR_CONSENT_GIVEN, '', 0);
};

export const enableStatistics = (): void => {
  setCookie(GDPR_STATISTICS_ENABLED, true, 365);
};

export const enableFunctional = async (): Promise<void> => {
  await setCookie(GDPR_FUNCTIONAL_ENABLED, true, 365);
};

export const disableStatistics = (): void => {
  setCookie(GDPR_STATISTICS_ENABLED, false, 365);
};

export const disableFunctional = (): void => {
  setCookie(GDPR_FUNCTIONAL_ENABLED, false, 365);
};

export const setLocale = (locale) => {
  setCookie('locale', locale, 28);
};

export const setCountry = (country) => {
  setCookie('country', country, 28);
};

export const setOnboarding = (status) => {
  setCookie('onboarding', status);
};

export const setPreferLoginVariantCookie = (variant: string): void => {
  setServerSideCookie(null, PREFERRED_LOGIN_VARIANT, variant);
};

export const setPreviouslyLoggedIn = (state: boolean): void => {
  setCookie('previouslyLoggedIn', state);
};

const getCookiesAsObject = (): any =>
  `; ${document.cookie}`.split('; ').reduce((acc, value) => {
    const parts = value.split('=');
    return parts.length < 2 ? acc : { ...acc, [parts[0].trim()]: parts[1] };
  }, {});

export const getUserCookie = () => {
  try {
    return JSON.parse(getCookiesAsObject().user);
  } catch (e) {
    return null;
  }
};

export const getCountryCookie = () => {
  const innerDoc = document || null;
  if (innerDoc) {
    return getCookiesAsObject().country;
  }
  return null;
};

export const getFunctionalCookie = (): boolean | null => {
  try {
    return getCookiesAsObject()[GDPR_FUNCTIONAL_ENABLED] === 'true';
  } catch (e) {
    return null;
  }
};

export const getStatisticsCookie = (): boolean | null => {
  try {
    return getCookiesAsObject()[GDPR_STATISTICS_ENABLED] === 'true';
  } catch (e) {
    return null;
  }
};

export const getCookieConsent = (): string | null => {
  try {
    return getCookiesAsObject()[GDPR_CONSENT_GIVEN];
  } catch (e) {
    return null;
  }
};

export const getToken = (): string => {
  try {
    const { token } = parseCookies();
    return token;
  } catch (e) {
    return null;
  }
};

export const checkTermsAccepted = (termsCookieValue: string): boolean =>
  termsCookieValue === GDPR_VERSION;

export const isCookiesConsentGiven = (cookieValue: string): boolean =>
  cookieValue === GDPR_VERSION;
